<template>
  <div>
    <v-container class="mb-8 pb-8 gradient">
      <v-row justify="center" class="mt-6 mb-6">
        <v-img src="/static/monplanning.svg" max-width="300" max-height="80" contain />
      </v-row>
      <v-row justify="center">
        <v-col sm="8">
          <h1 class="text-h4 text-center">
            Essayez monplanning gratuitement
          </h1>
          <div class="text-h4 text-center font-weight-bold">
            pendant 30 jours,
            <span class="primary--text">sans aucune obligation.</span>
          </div>
          <div class="mt-3 text-center">
            Vous êtes professionnel ? Vous désirez découvrir
          </div>
          <div class="text-center">
            comment <strong>monplanning</strong> peut vous aider à travailler plus efficacement
            ?
          </div>


        </v-col>
      </v-row>
    </v-container>

    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="10" lg="8" xl="6">
          <v-card class="elevation-12">
            <v-toolbar elevation="0" color="primary" dark flat>
              <v-toolbar-title v-html="$t('register-title')"></v-toolbar-title>
              <v-spacer />
              <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn :href="source" icon large target="_blank" v-on="on">
                  <v-icon>mdi-code-tags</v-icon>
                </v-btn>
              </template>
<span>Source</span>
</v-tooltip>-->
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-btn icon large target="_blank" to="/" v-on="on">
                    <v-icon>mdi-information</v-icon>
                  </v-btn>
                </template>
                <span>aide monplanning</span>
              </v-tooltip>
            </v-toolbar>
            <v-card-text>
              <v-alert v-if="message" :type="successful ? 'success' : 'error'">{{ message }}</v-alert>
              <!-- <v-container>
                <v-row justify="center" block class="mb-2">
                  <v-btn
                    @click="provider = 'email'"
                    class="orange lighten-4 deep-orange--text"
                    width="150"
                    depressed
                  >
                    <v-icon left> mdi-email </v-icon>
                    <span>e-mail</span>
                  </v-btn>

                  <v-btn
                    @click="provider = 'facebook'"
                    class="ml-1 indigo lighten-1 white--text"
                    width="150"
                    depressed
                  >
                    <v-icon left> mdi-facebook </v-icon>
                    <span>facebook</span>
                  </v-btn>
                </v-row>
              </v-container> -->
              <v-form v-if="!successful" ref="form">
                <v-container>
                  <v-scale-transition>
                    <v-row v-show="provider == 'email'">
                      <v-text-field :label="$helpers.string.capitalizeI18N('first-name', {
                        required: true,
                      })
                        " v-model="user.firstName" name="firstName" prepend-inner-icon="mdi-account" type="text"
                        :rules="$helpers.rule.notEmpty()" outlined />
                    </v-row>
                  </v-scale-transition>
                  <v-scale-transition>
                    <v-row v-show="provider == 'email'">
                      <v-text-field :label="$helpers.string.capitalizeI18N('last-name', {
                        required: true,
                      })
                        " v-model="user.lastName" name="lastName" prepend-inner-icon="mdi-account" type="text"
                        :rules="$helpers.rule.notEmpty()" outlined />
                    </v-row>
                  </v-scale-transition>
                  <v-scale-transition>
                    <v-row v-show="provider == 'email'">
                      <v-text-field :label="$helpers.string.capitalizeI18N('email', {
                        required: true,
                      })
                        " v-model="user.email" name="email" prepend-inner-icon="mdi-email" type="email"
                        :rules="$helpers.rule.notEmpty()" outlined />
                    </v-row>
                  </v-scale-transition>
                  <v-scale-transition>
                    <v-row v-show="provider == 'email'">
                      <v-text-field :label="$helpers.string.capitalizeI18N('phone-number', {
                        required: true,
                      })
                        " v-model="user.phoneNumber" name="phoneNumber" type="phone" prepend-inner-icon="mdi-phone"
                        :rules="$helpers.rule.notEmpty()" outlined />
                    </v-row>
                  </v-scale-transition>
                  <v-scale-transition>
                    <v-row v-show="provider == 'email'">
                      <v-text-field :type="showPassword ? 'text' : 'password'" :label="$helpers.string.capitalizeI18N('password', {
                        required: true,
                      })
                        " v-model="user.password" name="password" prepend-inner-icon="mdi-lock"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword" :rules="$helpers.rule.notEmpty()" outlined />
                    </v-row>
                  </v-scale-transition>
                  <v-row>
                    <v-text-field :label="$helpers.string.capitalizeI18N('organization-name', {
                      required: true,
                    })
                      " v-model="user.organizationName" :error="organizationNameError" prepend-inner-icon="mdi-account"
                      type="text" :rules="$helpers.rule.notEmpty()" outlined />
                  </v-row>
                  <v-row align="center">
                    <v-select :items="billPlans" :label="$helpers.string.capitalizeI18N('bill-plan', {
                      required: true,
                    })
                      " v-model="user.billPlan" :item-text="getBillPlanName"
                      :prepend-inner-icon="user.billPlan == 'starter' ? 'mdi-leaf' : 'mdi-tree'" outlined>
                      <template v-slot:selection="data">
                        <v-container class="pt-0 pb-0">
                          <v-row align="center">
                            {{ getBillPlanName(data.item) }}
                            <v-spacer />
                            <v-chip color="success"><v-icon small class="mr-2">mdi-check</v-icon>{{
                              $t("free-30-day-trial") }}</v-chip>
                          </v-row>
                        </v-container>
                      </template>
                      <template v-slot:item="data">
                        <v-container class="pt-0 pb-0">
                          <v-row align="center">
                            <v-icon left>{{ data.item == 'starter' ? 'mdi-leaf' : 'mdi-tree' }}</v-icon>
                            {{ getBillPlanName(data.item) }}
                            <v-spacer />
                            <v-chip color="success"><v-icon small class="mr-2">mdi-check</v-icon>{{
                              $t("free-30-day-trial") }}</v-chip>
                          </v-row>
                        </v-container>
                      </template>
                    </v-select>
                    <!-- <v-btn :to="$helpers.router.getPublicLink('pricing')" target="_blank" class="ml-4" text
                      color="primary">{{
                        $t("compare")
                      }}<v-icon class="ml-2">mdi-open-in-new</v-icon></v-btn> -->
                  </v-row>
                  <v-row align="center" justify="start">
                    <v-col class="text-overline" md="5">
                      Vous avez un code de parrainage ?
                    </v-col>
                    <v-col md="3"><v-text-field v-model="user.referralCode" hide-details type="text" outlined
                        dense /></v-col>
                  </v-row>
                  <v-row align="center" justify="start">
                    <v-col class="text-overline" md="5">
                      Vous avez un code promotionnel ?
                    </v-col>
                    <v-col md="3"><v-text-field v-model="user.promotionalCode" hide-details type="text" outlined
                        dense /></v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-btn color="primary" @click="handleRegister" :disabled="loading" v-show="provider == 'email'"
                      block>
                      <span v-show="loading" class="spinner-border spinner-border-sm"></span>{{ $t("start-free-trial")
                      }}
                    </v-btn>

                    <!-- <v-btn
                      @click="loginWithFacebook()"
                      depressed
                      outlined
                      class="indigo lighten-1 white--text"
                      v-show="provider == 'facebook'"
                      block
                      ><v-icon color="white" left> mdi-facebook </v-icon
                      >{{ $t("start-free-trial")
                      }}<v-icon color="white" right>
                        mdi-chevron-right
                      </v-icon></v-btn
                    > -->
                  </v-row>

                  <v-row class="mt-8">
                    <small>* {{ $t("required-fields") }}</small>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions v-if="!successful">
              <div>
                <v-btn class="font-weight-medium" text to="/admin/login" color="primary">Vous avez déjà un compte
                  ?</v-btn>
              </div>
              <!-- <v-btn to="/admin/login" outlined color="primary">
                <v-icon>mdi-account-reactivate</v-icon>
                <span class="d-none d-md-inline ml-2">{{ $t("login") }}</span>
              </v-btn>
              <v-spacer /> -->

              <!-- <v-facebook-login-scope app-id="158795539016779" version="v9.0">
              </v-facebook-login-scope> -->
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
// import { VFBLoginScope as VFacebookLoginScope } from "vue-facebook-login-component";

export default {
  name: "Register",
  components: {
    // VFacebookLoginScope,
  },
  data() {
    return {
      model: {},
      showPassword: false,
      provider: "email",
      user: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        password: "",
        organizationName: "",
        billPlan: "pro",
        promotionalCode: "",
        referralCode: "",
      },
      organizationNameError: false,
      facebookUser: null,
      loading: false,
      message: "",
      successful: false,
      billPlans: [
        'starter',
        // "essential",
        "pro",
        //"full",
      ],
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.adminauth.status.loggedIn;
    },
  },
  mounted() {
    if (this.$route.query.email) {
      this.user.email = this.$route.query.email;
    }

    if (this.$route.query.bill_plan) {
      this.user.billPlan = this.$route.query.bill_plan;
    }

    this.user.promotionalCode = localStorage.getItem("promotional_code");

    this.user.referralCode = localStorage.getItem("referral_code");
  },
  created() { },
  methods: {
    // registerWithFacebookAccessToken(accessToken) {
    //   this.$store
    //     .dispatch("adminauth/registerFacebook", {
    //       user: this.user,
    //       accessToken: accessToken,
    //     })
    //     .then(
    //       (data) => {
    //         this.user.email = data.email;
    //         this.user.password = data.generatedPassword;

    //         this.handleLoginAfterRegistration();
    //       },
    //       (error) => {
    //         this.successful = false;

    //         this.$helpers.snackbar.handleError(error);
    //       }
    //     );
    // },
    // loginWithFacebook() {
    //   if (!this.user.organizationName) {
    //     this.organizationNameError = true;
    //     return;
    //   }

    //   this.message = "";
    //   this.submitted = true;

    //   window.FB.getLoginStatus((statusResponse) => {
    //     console.dir(statusResponse);
    //     if (statusResponse?.status !== "connected") {
    //       window.FB.login((response) => {
    //         console.dir(response);
    //         if (response?.status !== "connected") return;

    //         this.registerWithFacebookAccessToken(
    //           response.authResponse.accessToken
    //         );
    //       });
    //     } else {
    //       // go to login
    //       this.registerWithFacebookAccessToken(
    //         statusResponse.authResponse.accessToken
    //       );
    //     }
    //   });
    // },
    getBillPlanName(billPlan) {
      return this.$helpers.string.capitalizeI18N("bill-plans." + billPlan);
    },
    handleLoginAfterRegistration() {
      this.$store.dispatch("adminauth/login", this.user).then(
        () => {
          this.$helpers.router.push("admin.dashboard");
        },
        (error) => {
          this.loading = false;

          this.$helpers.snackbar.handleError(error);
        }
      );
    },
    handleRegister() {
      if (!this.$refs.form.validate()) return;

      this.message = "";
      this.submitted = true;

      this.$store.dispatch("adminauth/register", this.user).then(
        (data) => {
          console.log("data ok" + data);
          this.handleLoginAfterRegistration();
        },
        (error) => {
          this.successful = false;

          this.$helpers.snackbar.handleError(error);
        }
      );
    },
  },
};
</script>
<style scoped>
.fb-btn.v-btn--outlined {
  border: thin solid #cccccc;
}
</style>